import React, { useState, useEffect } from "react";
import adicto from "./../../const/api";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import images from "../../Images/images";

export const DetailVocabulary = (props) => {
  const [voc, setVocabulary] = useState({});
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const playAudio = (url) => {
    let audio = new Audio(url);
    audio.play();
  };

  useEffect(() => {
    const fetchVocabulary = async () => {
      await adicto
        .get(`vocabularies/${props.params.id}`)
        .then((res) => {
          const lang = "name_" + i18n.language;
          const grouped = [];
          res.data.translations.forEach((tr) => {
            if (tr.category) {
              if (grouped.find((g) => g.id === tr.category.id)) {
                grouped.find((g) => g.id === tr.category.id).data.push(tr);
              } else {
                grouped.push({
                  id: tr.category.id,
                  name: tr.category[lang],
                  data: [tr],
                });
              }
            } else {
              if (grouped.find((g) => g.name === "")) {
                grouped.find((g) => g.name === "").data.push(tr);
              } else {
                grouped.push({ name: "", data: [tr] });
              }
            }
          });
          res.data.translations = grouped;
          setVocabulary(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchVocabulary();
  }, [props.params.id, t, i18n.language]);

  return (
    <>
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row no-gutters mx-n1 mx-md-n2">
                <div className="col-12 mb-3 px-1 px-md-2">
                  <div className="card border-0 shadow">
                    {!loading ? (
                      <>
                        <div className="embed-responsive embed-responsive-21by9">
                          <img
                            src={voc.images}
                            alt=""
                            className="embed-responsive-item contain cover"
                          />
                        </div>

                        <div className="card-body">
                          <div className="font-weight-bold text-main font-25 mb-3 d-block">
                            {voc.title}
                          </div>

                          {voc.translations.map((v, vindex) => {
                            return (
                              <React.Fragment key={vindex}>
                                <span className="text-muted font-22 font-weight-bold mt-2 mb-3">
                                  {v.name !== ""
                                    ? v.name
                                    : t("otherTranslations")}
                                </span>

                                <div className="row border-bottom pb-2 bottom-20 mt-2">
                                  {v.data.map((d, dindex) => {
                                    return (
                                      <div
                                        key={dindex}
                                        className="col-12 col-md-6 mb-2"
                                      >
                                        <span className="text-main d-block font-20 mb-0">
                                          <span className="font-weight-bold">
                                            <Link
                                              to={"/word/" + d.spanish?.slug}
                                              className="text-main"
                                            >
                                              {d.spanish?.word}{" "}
                                            </Link>
                                            {d.spanish?.voices &&
                                            d.spanish.voices.es ? (
                                              <i
                                                className="mdi mdi-volume-high pointer"
                                                onClick={() =>
                                                  playAudio(d.spanish.voices.es)
                                                }
                                              ></i>
                                            ) : null}
                                          </span>
                                        </span>

                                        <span className="font-15 font-weight-bold text-success">
                                          {d.azerbaijani?.word}{" "}
                                          <span className="text-muted">
                                            {d.type
                                              ? `(${
                                                  d.type[
                                                    `name_${i18n.language}`
                                                  ]
                                                })`
                                              : ""}
                                          </span>
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </React.Fragment>
                            );
                          })}

                          {voc.phrases.length > 0 ? (
                            <>
                              <span className="d-block text-muted font-22 font-weight-bold mt-2">
                                {t("phrases")}
                              </span>

                              <div className="row border-bottom pb-2">
                                {voc.phrases.map((ph, phindex) => {
                                  return (
                                    <div
                                      key={phindex}
                                      className="col-12 col-md-6 mb-2"
                                    >
                                      <span className="text-main d-block font-16 mb-0">
                                        <span className="font-weight-bold">
                                          {ph.title_es}
                                        </span>
                                      </span>

                                      <span className="font-14 font-weight-bold text-success">
                                        {ph.title_az}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <div className="p-20">
                        <Skeleton count={20} height={15} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <Link to="/contact-us">
                <div className="bg-gray w-100 rounded sticky-banner">
                  <img src={images.MainBanner} alt="Ad" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
