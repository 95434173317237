import React, { useState, useEffect } from "react";
import { ServiceBlock } from "./../Elements/Blocks";
import { ContactBox } from "./../Elements/ContactBox";
import { Link } from "react-router-dom";
import adicto from "../../const/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Pagination from "./../Elements/Pagination";
import { LoadingSkeleton } from "../Elements/LoadingSkeleton";
import images from "../../Images/images";

const Services = (props) => {
  const [services, setServices] = useState([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(15);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getServices = async () => {
      setLoading(true);
      await adicto
        .get(`services?page=${page}&per_page=8`)
        .then((res) => {
          setServices(res.data.data);
          setPage(res.data.current_page);
          setLastPage(res.data.last_page);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getServices();
  }, [t, page]);

  const changePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row my-gutters mx-n1 mx-md-n2 mb-3">
                {loading ? (
                  <LoadingSkeleton />
                ) : (
                  services.map((s, index) => {
                    return (
                      <ServiceBlock loading={loading} key={index} service={s} />
                    );
                  })
                )}
              </div>
              {loading ? (
                <Skeleton count={2} />
              ) : last_page > 1 ? (
                <Pagination
                  changePage={changePage}
                  page={page}
                  last_page={last_page}
                />
              ) : null}

              <div className="row my-gutters mx-n1 mx-md-n2">
                <div className="col-12 mb-3 pl-2">
                  <h2 className="font-weight-bold">{t("contactUs")}</h2>
                </div>

                {props.info.users.map((u, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
                        <ContactBox
                          text={u.phone}
                          class={"mdi-phone-outline"}
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
                        <ContactBox
                          text={u.email}
                          class={"mdi-email-outline"}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <Link to="/contact-us">
                <div className="bg-gray w-100 rounded sticky-banner">
                  <img src={images.MainBanner} alt="Ad" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ info }) => {
  return { info };
};

export default connect(mapStateToProps)(Services);
