import { LOADING_ON, LOADING_OFF, SET_LANGUAGE, SET_INFO } from "./../types";
import adicto from "./../../const/api";

export const loadingOn = () => ({ type: LOADING_ON });
export const loadingOff = () => ({ type: LOADING_OFF });

export const setLanguage = (payload) => {
  return {
    type: SET_LANGUAGE,
    payload,
  };
};

export const getInfo = () => async (dispatch) => {
  await adicto.get("info").then((res) => {
    dispatch({
      type: SET_INFO,
      payload: res.data,
    });
  });
};
