import React, { useEffect, useState } from "react";
import adicto from "../../const/api";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import images from "../../Images/images";
import { Link } from "react-router-dom";

export const DetailArticle = (props) => {
  const [article, setArticle] = useState({ images: [] });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchArticle = async () => {
      await adicto
        .get(`blog/${props.params.id}`)
        .then((res) => {
          setArticle(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchArticle();
  }, [props.params.id, t]);

  return (
    <>
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row no-gutters mx-n1 mx-md-n2">
                <div className="col-12 mb-3 px-1 px-md-2">
                  <div className="card border-0 shadow">
                    {!loading ? (
                      <>
                        <div
                          id="carouselExampleIndicators"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            {article?.images.map((a, index) => {
                              return (
                                <li
                                  data-target="#carouselExampleIndicators"
                                  data-slide-to={index}
                                  key={index}
                                  className="active"
                                ></li>
                              );
                            })}
                          </ol>
                          <div className="carousel-inner">
                            {article?.images.map((a, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`carousel-item ${
                                    index === 0 ? "active" : ""
                                  }`}
                                  data-interval="7000"
                                >
                                  <div className="embed-responsive embed-responsive-21by9">
                                    <img
                                      src={a.url}
                                      alt=""
                                      className="embed-responsive-item contain cover"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>

                        <div className="card-body">
                          <span className="card-title font-weight-bold text-main font-25 mb-3 d-block">
                            {article?.title}
                          </span>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: article?.description,
                            }}
                          ></p>
                        </div>
                      </>
                    ) : (
                      <div className="p-20">
                        <Skeleton count={20} height={15} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <Link to="/contact-us">
                <div className="bg-gray w-100 rounded sticky-banner">
                  <img src={images.MainBanner} alt="Ad" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
