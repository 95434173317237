import {
  LOADING_OFF,
  LOADING_ON,
  SET_LANGUAGE,
  GET_LANGUAGE,
  SET_NOTIFICATION,
  SET_INFO,
  GET_INFO,
} from "./../types";

export function loaderReducer(loaderCount = 0, action) {
  switch (action.type) {
    case LOADING_ON:
      return ++loaderCount;
    case LOADING_OFF:
      return loaderCount === 0 ? 0 : --loaderCount;
    default:
      return loaderCount;
  }
}

const initialNotification = {
  notify: false,
  prevNotify: false,
  message: "",
};

export function notificationReducer(data = initialNotification, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      console.log(action.payload);
      return { ...action.payload };
    default:
      return data;
  }
}

const initialLanguage = "az";
export function languageReducer(data = initialLanguage, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.paylaod;
    case GET_LANGUAGE:
      return data;
    default:
      return data;
  }
}

const initialInfo = {
  users: [],
  data: {
    about_us: "",
    facebook: "/",
    instagram: "/",
    linkedIn: "/",
  },
  level: [],
};
export function infoReducer(data = initialInfo, action) {
  switch (action.type) {
    case SET_INFO:
      return { ...action.payload };
    case GET_INFO:
      return data;
    default:
      return data;
  }
}
