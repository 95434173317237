import React, { useEffect, useState } from "react";
import adicto from "./../../const/api";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import images from "../../Images/images";

export const DetailText = (props) => {
  const [text, setText] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchArticle = async () => {
      await adicto
        .get(`texts/${props.params.id}`)
        .then((res) => {
          const trs = [];
          let triplet = [];
          let count = 0;
          res.data.translations.forEach((tr) => {
            count++;
            triplet.push({ az: tr.azerbaijani.word, es: tr.spanish.word });
            if (count === 3) {
              count = 0;
              trs.push(triplet);
              triplet = [];
            }
          });
          res.data.translations = trs;
          console.log(res.data);
          setText(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchArticle();
  }, [props.params.id, t]);

  return (
    <>
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row no-gutters mx-n1 mx-md-n2">
                <div className="col-12 mb-3 px-1 px-md-2">
                  <div className="card border-0 shadow">
                    {!loading ? (
                      <>
                        <div className="embed-responsive embed-responsive-21by9">
                          <img
                            src={text.image}
                            alt=""
                            className="embed-responsive-item contain cover"
                          />
                        </div>

                        <div className="card-body">
                          <span className="card-title font-weight-bold text-main font-25 mb-3 d-block">
                            {text.title}
                          </span>

                          <p
                            className="mb-3"
                            dangerouslySetInnerHTML={{
                              __html: text?.body,
                            }}
                          ></p>

                          {text.translations.length > 0 && (
                            <span className="card-title font-weight-bold text-main font-25 mb-3 d-block">
                              {t("selectedWords")}
                            </span>
                          )}

                          <div className="row">
                            {text.translations?.map((tr) => {
                              return (
                                <div className="col-12 col-md-6 mb-3">
                                  <span className="d-block">
                                    {tr[0].az} - {tr[0].es}
                                  </span>
                                  {tr[1] ? (
                                    <span className="d-block">
                                      {tr[1].az} - {tr[1].es}
                                    </span>
                                  ) : null}
                                  {tr[2] ? (
                                    <span className="d-block">
                                      {tr[2].az} - {tr[2].es}
                                    </span>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="p-20">
                        <Skeleton count={20} height={15} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <Link to="/contact-us">
                <div className="bg-gray w-100 rounded sticky-banner">
                  <img src={images.MainBanner} alt="Ad" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
