//Banners
import ContactBanner from "./contact-banner.jpg";
import MainBanner from "./main-banner.jpg";

//Header
import Logo from "./new-logo.png";
//Spinner
import Spinner from "./spinner.gif";
//Loader
import Loader from "./loading.gif";

//Homepage
import TranslationImg from "./Translation-icon.png";
import ConjugationImg from "./Signature.png";
import VocabularyImg from "./Vocabulary-icon.png";
import ArticlesImg from "./Group.png";
import TextsImg from "./book.png";
import SectionBg from "./Background.svg";

//Footer
import FooterBg from "./Footerbg.png";

//About
import AboutBg from "./AboutBg.png";

//Translation
import List from "./list.svg";

//Widgets
import PopularIcon from "./popular-icon.png";
import CalendarIcon from "./calendar-icon.png";

//Details
import Background from "./Background.svg";
import Background3 from "./Background3.png";
import Background32 from "./Background32.png";
import Book from "./book.png";

export default {
  //Header
  Logo,
  //Home
  TranslationImg,
  ConjugationImg,
  VocabularyImg,
  ArticlesImg,
  TextsImg,
  SectionBg,
  //Footer
  FooterBg,
  //About
  AboutBg,
  //Translation
  List,
  //Loader
  Loader,
  //Widgets
  PopularIcon,
  CalendarIcon,

  Background,
  Background3,
  Background32,
  Book,
  //Spinner
  Spinner,

  //Banners
  ContactBanner,
  MainBanner,
};
