import React from "react";
import { useTranslation } from "react-i18next";

const Pagination = (props) => {
  const { t } = useTranslation();

  const changePage = (page, valid) => {
    if (valid) {
      props.changePage(page);
    }
  };

  function pagination(c, m) {
    var current = props.page,
      last = props.last_page,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;
    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }
    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return rangeWithDots;
  }

  const renderNumbers = () => {
    let numbers = [];
    let arr = pagination();
    arr.forEach((a) => {
      if (a === "...") {
        numbers.push(
          <li className="page-item disabled">
            <span className="page-link rounded-pill border-0 mx-1">...</span>
          </li>
        );
      } else {
        numbers.push(
          <li
            key={a}
            onClick={() => changePage(a, true)}
            className={`page-item ${props.page === a ? "active" : null}`}
          >
            <span className="page-link pointer rounded-pill border-0 shadow-lg mx-1 text-main">
              {a}
            </span>
          </li>
        );
      }
    });
    return numbers.map((n, index) => (
      <React.Fragment key={index}>{n}</React.Fragment>
    ));
  };

  return (
    <div className="col-12">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center font-weight-bold">
          <li
            className={`page-item d-lg-none d-sm-block ${
              props.page === 1 ? "disabled" : ""
            }`}
            onClick={() => changePage(props.page - 1, props.page !== 1)}
          >
            <span
              className="page-link pointer rounded-pill border-0 shadow-lg text-main"
              tabIndex="-1"
              aria-disabled={props.page === 1 ? "true" : "false"}
            >
              <i aria-hidden="true" className="mdi mdi-chevron-left mr-2"></i>
              {t("previous")}
            </span>
          </li>
          {renderNumbers()}
          <li
            className={`page-item d-lg-none d-sm-block ${
              props.page === props.last_page ? "disabled" : ""
            }`}
            onClick={() =>
              changePage(props.page + 1, props.page !== props.last_page)
            }
          >
            <span
              className="page-link pointer rounded-pill border-0 shadow-lg text-main"
              tabIndex="-1"
              aria-disabled={props.page === props.last_page ? "true" : "false"}
            >
              {t("next")}
              <i aria-hidden="true" className="mdi mdi-chevron-right ml-2"></i>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
