import React, { useState } from "react";
import { ContactBox } from "./../Elements/ContactBox";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import images from "../../Images/images";
import adicto from "../../const/api";

const Contact = (props) => {
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState({
    name: { text: "", error: false },
    phone: { text: "", error: false },
    body: { text: "", error: false },
  });
  const { t } = useTranslation();

  const checkPhone = () => {
    let a = new RegExp(/^[0-9\s+]+$/);
    return a.test(message.phone.text);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (
      message.phone.text.length &&
      checkPhone() &&
      message.name.text.length &&
      message.body.text.length
    ) {
      await adicto
        .post("contacts", {
          name: message.name.text,
          phone: message.phone.text,
          body: message.body.text,
        })
        .then((res) => {
          setToast(true);
          setTimeout(() => {
            setToast(false);
          }, 3000);
          setMessage({
            name: { text: "", error: false },
            phone: { text: "", error: false },
            body: { text: "", error: false },
          });
        });
    } else {
      const all = { ...message };
      if (!message.phone.text || !checkPhone()) {
        all.phone.error = true;
      }
      if (!message.name.text) {
        all.name.error = true;
      }
      if (!message.body.text) {
        all.body.error = true;
      }
      setMessage(all);
    }
  };

  const handleMessageChange = (e, key) => {
    const all = { ...message };
    all[key].text = e.target.value;
    all[key].error = false;
    setMessage(all);
    checkPhone();
  };

  return (
    <>
      {/* {toast ? <div className="my-toast">Hi there everybody</div> : null} */}
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row no-gutters mx-n1 mx-lg-n2">
                <div className="col-12 col-md-7 px-1 px-lg-2 mb-3">
                  <div className="bg-white rounded shadow px-2 px-lg-5 py-2 py-lg-3 text-center">
                    <div
                      className={`alert alert-primary my-toast ${
                        toast ? "" : "hide-toast"
                      }`}
                      role="alert"
                    >
                      {t("messageSuccess")}
                    </div>
                    <h2 className="font-weight-bold">{t("writeUs")}</h2>

                    <form>
                      <div className="form-group">
                        <input
                          className="form-control bg-gray-2 border-0"
                          placeholder={t("name")}
                          value={message.name.text}
                          onChange={(e) => handleMessageChange(e, "name")}
                          aria-describedby="emailHelp"
                        />
                        {message.name.error ? (
                          <p className="text-danger my-danger">
                            {t("nameRequired")}
                          </p>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <input
                          className="form-control bg-gray-2 border-0"
                          placeholder={t("mobileNumber")}
                          value={message.phone.text}
                          onChange={(e) => handleMessageChange(e, "phone")}
                          aria-describedby="emailHelp"
                        />
                        {message.phone.error ? (
                          <p className="text-danger my-danger">
                            {t("phoneRequired")}
                          </p>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <textarea
                          placeholder={t("note")}
                          value={message.body.text}
                          onChange={(e) => handleMessageChange(e, "body")}
                          id="100%"
                          cols="auto"
                          rows="5"
                          className="w-100 bg-gray-2 border-0 rounded focus-none p-2 p-md-3"
                        ></textarea>
                        {message.body.error ? (
                          <p className="text-danger my-danger">
                            {t("messageRequired")}
                          </p>
                        ) : null}
                      </div>
                      <button
                        onClick={sendMessage}
                        className="btn btn-primary mb-3 mb-lg-2 px-5 bg-main"
                      >
                        {t("send")}
                      </button>
                    </form>
                  </div>
                </div>

                <div className="col-12 col-md-5 px-1 px-lg-2">
                  <div className="row no-gutters mx-n1 mx-md-n2">
                    {props.info.users.map((u, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="col-12 mb-3 px-1 px-md-2">
                            <ContactBox
                              text={u.phone}
                              class={"mdi-phone-outline"}
                            />
                          </div>
                          <div className="col-12 mb-3 px-1 px-md-2">
                            <ContactBox
                              text={u.email}
                              class={"mdi-email-outline"}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <div className="bg-gray w-100 rounded sticky-banner">
                <img src={images.ContactBanner} alt="Ad" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ info }) => {
  return { info };
};
export default connect(mapStateToProps)(Contact);
