import React from "react";
import "./App.css";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import Services from "./Components/Pages/Services";
import Articles from "./Components/Pages/Articles";
import { Route, Switch, Redirect } from "react-router-dom";
import Vocabulary from "./Components/Pages/Vocabulary";
import Texts from "./Components/Pages/Texts";
import { DetailVocabulary } from "./Components/Pages/Vocabulary-Detail";
import { DetailText } from "./Components/Pages/Text-Detail";
import { DetailArticle } from "./Components/Pages/Article-Detail";
import DetailService from "./Components/Pages/Service-Detail";
import Translation from "./Components/Pages/Translation";
import { getInfo } from "./redux/actions/index";
import { connect } from "react-redux";
import { PageHeading } from "./Components/Elements/PageHeading";
import { withTranslation } from "react-i18next";

class App extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", "az");
    }
    this.props.getInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.t !== prevProps.t) {
      this.props.getInfo();
    }
  }

  render() {
    return (
      <>
        <div className="App">
          <Header />
          <div className="main-wrap">
            <Switch>
              <Route exact path="/">
                <Home conjugation={false} />
              </Route>
              <Route exact path="/conjugation">
                <Home conjugation={true} />
              </Route>
              <Route exact path="/about">
                <PageHeading heading={"aboutUs"} url="/about" />
                <About />
              </Route>
              <Route exact path="/services">
                <PageHeading heading={"ourServices"} url="/services" />
                <Services />
              </Route>
              <Route
                exact
                path="/services/:id"
                render={({ match }) => (
                  <>
                    <PageHeading heading={"ourServices"} url="/services" />
                    <DetailService params={match.params} />
                  </>
                )}
              />
              <Route exact path="/contact-us">
                <PageHeading heading={"contact"} url="/contact-us" />
                <Contact />
              </Route>
              <Route exact path="/texts">
                <PageHeading heading={"texts"} url="/texts" />
                <Texts />
              </Route>
              <Route
                exact
                path="/texts/:id"
                render={({ match }) => (
                  <>
                    <PageHeading heading={"texts"} url="/texts" />
                    <DetailText params={match.params} />
                  </>
                )}
              />
              <Route exact path="/vocabulary">
                <PageHeading heading={"vocabulary"} url="/vocabulary" />
                <Vocabulary />
              </Route>
              <Route
                exact
                path="/vocabulary/:id"
                render={({ match }) => (
                  <>
                    <PageHeading heading={"vocabulary"} url="/vocabulary" />
                    <DetailVocabulary params={match.params} />
                  </>
                )}
              />
              <Route exact path="/articles">
                <PageHeading heading={"articles"} url="/articles" />
                <Articles />
              </Route>
              <Route
                exact
                path="/articles/:id"
                render={({ match }) => (
                  <>
                    <PageHeading heading={"articles"} url="/articles" />
                    <DetailArticle params={match.params} />
                  </>
                )}
              />
              <Route
                exact
                path="/word/:slug"
                render={({ match }) => (
                  <>
                    <PageHeading heading={"translation"} url={null} />
                    <Translation params={match.params} />
                  </>
                )}
              />
              <Route
                exact
                path="/conjugation/:slug"
                render={({ match }) => (
                  <>
                    <PageHeading heading={"translation"} url={null} />
                    <Translation params={match.params} />
                  </>
                )}
              />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default connect(null, { getInfo })(withTranslation()(App));
