import React from "react";
import images from "../../Images/images";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const PageHeading = ({ heading, url }) => {
  const { t } = useTranslation();
  return (
    <div
      className="main-pages pb-5 pt-4 mb-3 mb-lg-4"
      style={{ backgroundImage: `url(${images.AboutBg})` }}
    >
      <div className="container-lg">
        {url ? (
          <Link to={url}>
            <h1 className="font-weight-bold text-white main-title-2 position-relative">
              {t(heading)}
            </h1>
          </Link>
        ) : (
          <h1 className="font-weight-bold text-white main-title-2 position-relative">
            {t(heading)}
          </h1>
        )}
      </div>
    </div>
  );
};
