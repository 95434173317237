import React from "react";
import { TextBlock } from "./../Elements/Blocks";
import { connect } from "react-redux";
import adicto from "./../../const/api";
import Pagination from "../Elements/Pagination";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { LoadingSkeleton } from "../Elements/LoadingSkeleton";
import { Link } from "react-router-dom";
import images from "../../Images/images";

class Texts extends React.Component {
  state = {
    texts: {},
    page: 1,
    per_page: 8,
    last_page: 1,
    level_id: 1,
    level_name: "A1",
    loading: true,
  };

  fetchTexts = async () => {
    const { per_page, level_id, level_name, texts } = this.state;
    this.setState({ loading: true });
    await adicto
      .get(
        `texts?page=${texts[level_name].page}&per_page=${per_page}&level_id=${level_id}`
      )
      .then((res) => {
        let texts = { ...this.state.texts };
        texts[level_name].data = res.data.data;
        texts[level_name].page = res.data.current_page;
        texts[level_name].last_page = res.data.last_page;
        this.setState({
          texts: texts,
          page: res.data.current_page,
          last_page: res.data.last_page,
          loading: false,
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.info.level.length !== prevProps.info.level.length) {
      this.setLevels();
    }
    if (this.props.t !== prevProps.t) {
      this.fetchTexts();
    }
  }

  setLevels() {
    if (this.props.info.level.length > 0) {
      let texts = {};
      this.props.info.level.forEach((l) => {
        texts[l.name] = { page: 1, loading: true, data: [] };
      });
      this.setState({ texts, level_id: this.props.info.level[0].id }, () => {
        this.fetchTexts();
      });
    }
  }

  changePage = (page) => {
    let texts = { ...this.state.texts };
    texts[this.state.level_name].page = page;
    this.setState(
      {
        texts,
        page,
      },
      () => {
        this.fetchTexts();
      }
    );
  };

  componentDidMount = () => {
    this.setLevels();
  };

  setLevel = (level_id, level_name) => {
    this.setState({ level_id, level_name }, () => {
      if (!this.state.texts[this.state.level_name].data.length) {
        this.fetchTexts();
      } else {
        this.setState({
          page: this.state.texts[this.state.level_name].page,
          last_page: this.state.texts[this.state.level_name].last_page,
        });
      }
    });
  };

  render() {
    return (
      <>
        <div className="services-section-one">
          <div className="container-lg">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="row no-gutters mx-n1 mx-md-n2 mb-3">
                  <div className="col-12 mb-3">
                    {this.props.info.level.length ? (
                      <div
                        className="list-group user-list listmenu-filter rounded-pill px-1 py-1 bg-gray-2"
                        id="list-tab"
                        role="tablist"
                      >
                        {this.props.info.level.map((l, index) => {
                          return (
                            <a
                              className={`list-group-item list-group-item-action rounded-pill bg-gray-2 border-0 p-0 d-inline float-left font-14 ${
                                index === 0 ? "active" : ""
                              }`}
                              key={index}
                              onClick={() => this.setLevel(l.id, l.name)}
                              id={`list-home${l.id}-list`}
                              data-toggle="list"
                              href={`#list-home${l.id}`}
                              role="tab"
                              aria-controls={`home${l.id}`}
                            >
                              <span className="py-1 d-block">{l.name}</span>
                            </a>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent2">
                      {this.state.loading ? (
                        <div className="row no-gutters">
                          <LoadingSkeleton />
                        </div>
                      ) : (
                        this.props.info.level.map((l, index) => {
                          return (
                            <div
                              className={`tab-pane fade show ${
                                l.id === this.state.level_id ? "active" : ""
                              }`}
                              key={index}
                              id={`list-home${l.id}`}
                              role="tabpanel"
                              aria-labelledby={`list-home${l.id}-list`}
                            >
                              <div className="row no-gutters">
                                {this.state.texts[l.name]?.data.map(
                                  (v, index2) => {
                                    return <TextBlock key={index2} text={v} />;
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
                {this.state.loading ? (
                  <Skeleton count={2} height={20} />
                ) : this.state.texts[this.state.level_name].data.last_page >
                  1 ? (
                  <Pagination
                    page={this.state.page}
                    last_page={this.state.last_page}
                    changePage={this.changePage}
                  />
                ) : null}
              </div>

              <div className="d-none d-lg-block col-12 col-lg-4">
                <Link to="/contact-us">
                  <div className="bg-gray w-100 rounded sticky-banner">
                    <img src={images.MainBanner} alt="Ad" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ info }) => {
  return {
    info,
  };
};

export default connect(mapStateToProps)(withTranslation()(Texts));
