import React from "react";
import images from "../../Images/images";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const About = (props) => {
  const { t } = props;
  return (
    <>
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row no-gutters mx-n1 mx-md-n2">
                <div className="col-12 mb-3 px-1 px-md-2">
                  <p
                    className="shadow p-3 p-lg-4"
                    dangerouslySetInnerHTML={{
                      __html: props.info?.data?.about_us,
                    }}
                  ></p>
                </div>

                <div className="col-12 mb-3 px-1 px-md-2">
                  <h2 className="font-weight-bold">{t("pageCreators")}</h2>
                </div>

                {props.info.users.map((u, index) => {
                  return (
                    <div
                      className="col-12 col-md-6 mb-3 px-1 px-md-2"
                      key={index}
                    >
                      <div className="item shadow card-bottom-three radius-10 overflow-hidden">
                        <div className="card border-0 w-100">
                          <div className="embed-responsive embed-responsive-16by9 pb-5">
                            <img
                              src={u.image}
                              alt=""
                              className="embed-responsive-item cover cover"
                            />
                          </div>

                          <div className="card-body text-center">
                            <span className="font-23 font-weight-bold card-title text-dark d-block mb-0">
                              {u.first_name} {u.last_name}
                            </span>
                            <span className="text-muted mb-2 d-block">
                              {u.description}
                            </span>

                            <div className="d-block">
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={u.facebook}
                                className="bg-icon rounded-circle d-inline-block mr-2"
                              >
                                <i
                                  aria-hidden="true"
                                  className="mdi mdi-24px mdi-facebook rounded-circle"
                                ></i>
                              </a>
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={u.linkedIn}
                                className="bg-icon rounded-circle d-inline-block mr-2"
                              >
                                <i
                                  aria-hidden="true"
                                  className="mdi mdi-24px mdi-linkedin rounded-circle"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <Link to="/contact-us">
                <div className="bg-gray w-100 rounded sticky-banner">
                  <img src={images.MainBanner} alt="Ad" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ info }) => {
  return {
    info,
  };
};
export default connect(mapStateToProps)(withTranslation()(About));
