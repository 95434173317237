import React from "react";
import { Link } from "react-router-dom";

export const WordWidget = (props) => {
  let audio = new Audio(props.voices ? props.voices.es : null);

  const start = () => {
    audio.play();
  };

  return (
    <div className="bg-white p-3 shadow card-top-main text-center py-3 h-100 d-flex align-items-center justify-content-center radius-10">
      <div>
        <div className="thumb mb-2 d-flex justify-content-center">
          <img src={props.icon} width="" height="" alt="" />
        </div>

        <span className="d-block font-15 font-weight-bold text-dark mb-2">
          {props.text}
        </span>

        <span className="d-block font-15 font-weight-bold text-main">
          <Link className="text-main" to={`/word/${props.slug}`}>{props.word}</Link>
          {props.voices !== null && (
            <i className="mdi mdi-volume-high pointer ml-1" onClick={start}></i>
          )}
        </span>
      </div>
    </div>
  );
};
