import React from "react";
import Skeleton from "react-loading-skeleton";

export const LoadingSkeleton = () => {
  return (
    <>
      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
        <Skeleton height={200} />
        <Skeleton count={4} />
      </div>
      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
        <Skeleton height={200} />
        <Skeleton count={4} />
      </div>
      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
        <Skeleton height={200} />
        <Skeleton count={4} />
      </div>
      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
        <Skeleton height={200} />
        <Skeleton count={4} />
      </div>
    </>
  );
};
