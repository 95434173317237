import React from "react";
import images from "../../Images/images";
import { WordWidget } from "../Elements/Widgets";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import history from "./../../const/history";
import adicto from "./../../const/api";
import Skeleton from "react-loading-skeleton";

const parts = [
  "indicative",
  "imperative",
  "subjunctive",
  "perfect_subjunctive",
  "perfect",
  "continuous",
];

class Translation extends React.Component {
  state = {
    isLoading: false,
    keyword: "",
    searchResults: [],
    open: false,
    favorites: [],
    loading: true,
    favoritesCount: 0,
    searchLoading: false,
    word: null,
    wordFetched: false,
    conjugation: {},
    conjugationFetched: false,
    synonyms: [],
    synonymsFetched: false,
    tabKey: "dictionary",
    arrow: null,
  };

  start = () => {
    const audio = new Audio(
      this.state.word.voices ? this.state.word.voices.es : null
    );
    audio.play();
  };

  startSub = (sub) => {
    const audio = new Audio(sub);
    audio.play();
  };

  handleTabChange = (tabKey) => {
    this.setState({ tabKey });
    switch (tabKey) {
      case "conjugation":
        if (!this.state.wordFetched) {
          this.getWord(this.props.params.slug);
        }
        if (!this.state.conjugationFetched) {
          adicto.get(`conjugation/${this.props.params.slug}`).then((res) => {
            this.setState({
              conjugation: res.data.conjugation,
              conjugationFetched: true,
            });
          });
        }
        break;
      case "synonyms":
        if (!this.state.synonymsFetched) {
          adicto.get(`synonym/${this.props.params.slug}`).then((res) => {
            this.setState({ synonyms: res.data, synonymsFetched: true });
          });
        }
        break;
      case "dictionary":
        if (!this.state.wordFetched) {
          this.getWord(this.props.params.slug);
        }
        break;
      default:
        break;
    }
  };

  setFavs = () => {
    if (localStorage.getItem("favorites")) {
      let favs = JSON.parse(localStorage.getItem("favorites"));
      this.setState({
        favorites: favs,
        favoritesCount: favs.length,
        isInFavorites: favs.map((f) => f.slug).includes(this.props.params.slug),
      });
    }
  };

  toggleSearchOpen = (e) => {
    if (
      e.target.id !== "searchResults" &&
      e.target.id !== "searchBox" &&
      e.target.id !== "favsBox" &&
      e.target.id !== "favsBoxImage"
    ) {
      this.setState({ open: false, arrow: null });
    }
  };

  handleKeyDown = (event) => {
    if (
      (event.keyCode >= 48 && event.keyCode <= 90) ||
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      document.getElementById("searchBox").focus();
    }
    if (event.target.id === "searchBox") {
      console.log("here");
      if (event.keyCode === 38) {
        if (this.state.searchResults.length > 0) {
          let arr = this.state.arrow;
          if (arr === null) {
            arr = 0;
          } else if (arr !== 0) {
            arr--;
          } else {
            arr = null;
          }
          this.setState({ arrow: arr });
        }
      } else if (event.keyCode === 40) {
        if (this.state.searchResults.length > 0) {
          let arr = this.state.arrow;
          if (arr === null) {
            arr = 0;
          } else if (arr < this.state.searchResults.length - 1) {
            arr++;
          } else {
            arr = null;
          }
          this.setState({ arrow: arr });
        }
      } else if (event.keyCode === 13) {
        if (this.state.searchResults.length > 0) {
          let url = `/${this.props.conjugation ? "conjugation" : "word"}/${
            this.state.searchResults[
              this.state.arrow !== null && this.state.arrow !== false
                ? this.state.arrow
                : 0
            ].slug
          }`;
          this.setState({ open: false }, () => {
            history.push(url);
          });
        }
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("click", this.toggleSearchOpen);
  }

  componentDidMount() {
    this.setFavs();
    window.addEventListener("keydown", (event) => this.handleKeyDown(event));
    window.addEventListener("click", (e) => this.toggleSearchOpen(e));

    let url =
      window.location.href.split("/")[3] === "conjugation"
        ? "conjugation"
        : "dictionary";
    this.setState(
      {
        tabKey: url,
      },
      () => {
        this.handleTabChange(url);
      }
    );
  }

  setLastSearched = (w) => {
    const { slug, word } = w;
    let list = [];
    if (localStorage.getItem("last_opened_words")) {
      list = JSON.parse(localStorage.getItem("last_opened_words"));
      if (list && list.length === 5) {
        list.shift();
      }
    }
    if (!list.some((l) => l.slug === slug)) {
      list.push({ slug, word });
    }
    localStorage.setItem("last_opened_words", JSON.stringify(list));
  };

  getWord = async (slug) => {
    this.setState({ isLoading: true });
    await adicto.get("translation/" + slug).then((res) => {
      const main_key = res.data.type === "az" ? "additional_az" : "additional";
      let additional = [];
      let main = [];
      this.setLastSearched(res.data);
      res.data.translatios.forEach((tr) => {
        if (tr[main_key] === "1") {
          if (
            additional.find(
              (m) =>
                m.category_id === tr.category_id && m.type_id === tr.type_id
            )
          ) {
            additional
              .find(
                (m) =>
                  m.category_id === tr.category_id && m.type_id === tr.type_id
              )
              .data.push({
                spanish: tr.spanish,
                azerbaijani: tr.azerbaijani,
                sentences: tr.sentences,
                meaning_az: tr.meaning_az,
                meaning_es: tr.meaning_es,
              });
          } else {
            additional.push({
              data: [
                {
                  spanish: tr.spanish,
                  azerbaijani: tr.azerbaijani,
                  sentences: tr.sentences,
                  meaning_az: tr.meaning_az,
                  meaning_es: tr.meaning_es,
                },
              ],
              category_id: tr.category_id,
              category: tr.category,
              type_id: tr.type_id,
              type: tr.type,
            });
          }
        } else {
          if (
            main.find(
              (m) =>
                m.category_id === tr.category_id && m.type_id === tr.type_id
            )
          ) {
            main
              .find(
                (m) =>
                  m.category_id === tr.category_id && m.type_id === tr.type_id
              )
              .data.push({
                spanish: tr.spanish,
                azerbaijani: tr.azerbaijani,
                sentences: tr.sentences,
                meaning_az: tr.meaning_az,
                meaning_es: tr.meaning_es,
              });
          } else {
            main.push({
              data: [
                {
                  spanish: tr.spanish,
                  azerbaijani: tr.azerbaijani,
                  sentences: tr.sentences,
                  meaning_az: tr.meaning_az,
                  meaning_es: tr.meaning_es,
                },
              ],
              category: tr.category,
              type: tr.type,
              category_id: tr.category_id,
              type_id: tr.type_id,
            });
          }
        }
      });
      this.setState({
        word: { ...res.data, main, additional },
        isLoading: false,
        wordFetched: true,
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.t !== prevProps.t) {
    }
    if (this.props.params.slug !== prevProps.params.slug) {
      this.setState(
        {
          wordFetched: false,
          conjugationFetched: false,
          synonymsFetched: false,
        },
        () => {
          let tabKey = this.state.tabKey;
          this.handleTabChange(tabKey);
        }
      );
      this.setFavs();
    }
  }

  addSpecialChar = (char) => {
    let keyword = this.state.keyword.split("");
    keyword.push(char);
    this.setState({ keyword: keyword.join("") }, () => {
      this.searchWords();
    });
  };

  searchWords = async (e) => {
    const keyword = e;
    if (e.length > 1) {
      this.setState({ searchLoading: true });
      await adicto
        .get("search", { params: { key_word: this.state.keyword } })
        .then((res) => {
          if (keyword === this.state.keyword) {
            this.setState({
              searchResults: res.data,
              open: res.data.length > 0,
              arrow: false,
              searchLoading: false,
            });
          }
        });
    } else if (e.length === 0) {
      this.setState({ searchResults: [], open: false });
    }
  };

  showFavorites = () => {
    this.setState({
      searchResults: [...this.state.favorites],
      open: this.state.favorites.length > 0,
    });
  };

  toggleSearchResult = (open) => {
    this.setState({ open: open });
  };

  handleOnFocus = () => {
    if (this.state.searchResults.length) {
      this.toggleSearchResult(true);
    } else if (!this.state.keyword.length) {
      const list = JSON.parse(localStorage.getItem("last_opened_words"));
      this.setState({
        searchResults: list || [],
        open: list && list.length > 0,
      });
    }
  };

  handleKeywordChange = async (e) => {
    this.setState({ keyword: e }, () => {
      this.searchWords(e);
    });
  };

  deleteFromFavorites = (slug) => {
    let favorites = [...this.state.favorites.filter((f) => f.slug !== slug)];
    this.setState({ favorites, favoritesCount: favorites.length }, () => {
      localStorage.setItem("favorites", JSON.stringify(favorites));
    });
  };

  toggleOfFavorites = () => {
    const slug = this.state.word.slug;
    const word = this.state.word.word;
    if (this.state.isInFavorites) {
      let favorites = [...this.state.favorites.filter((f) => f.slug !== slug)];
      this.setState(
        { favorites, isInFavorites: false, favoritesCount: favorites.length },
        () => {
          localStorage.setItem("favorites", JSON.stringify(favorites));
        }
      );
    } else {
      let favorites = [...this.state.favorites];
      if (!favorites.map((f) => f.slug).includes(slug)) {
        favorites.push({ slug, word });
        this.setState(
          { favorites, isInFavorites: true, favoritesCount: favorites.length },
          () => {
            localStorage.setItem("favorites", JSON.stringify(favorites));
          }
        );
      }
    }
  };

  render() {
    const { t, i18n } = this.props;
    const l = i18n.language;
    const { word, conjugation, synonyms } = this.state;
    // const specials = ["é", "ú", "í", "ó", "á", "ñ"];
    const favorites = this.state.favorites.map((f) => f.slug);
    return (
      <>
        <div className="services-section-one">
          <div className="container-lg">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="input-active input-group mb-3 mb-lg-4 ">
                      <input
                        type="text"
                        className="form-control rounded-pill shadow border-0"
                        placeholder={t("searchWithDots")}
                        aria-label={t("searchWithDots")}
                        value={this.state.keyword}
                        id="searchBox"
                        autoComplete="off"
                        onFocus={() => this.handleOnFocus()}
                        onChange={(e) =>
                          this.handleKeywordChange(e.target.value)
                        }
                        required
                      />
                      <div
                        className={`position-absolute search-dropdown w-100 bg-white radius-10 p-3 shadow ${
                          this.state.open ? "d-block" : "d-none"
                        }`}
                        id="searchResults"
                      >
                        <ul className="navbar-nav">
                          {this.state.searchResults.map((sr, index) => {
                            return (
                              <li
                                key={index}
                                className={`d-flex align-items-center bg-gray-2 rounded mb-1 ${
                                  this.state.arrow === index
                                    ? "active-result"
                                    : ""
                                }`}
                              >
                                <Link
                                  to={`/word/${sr.slug}`}
                                  className="p-2 d-block flex-grow-1 text-dark"
                                >
                                  {sr.word}
                                </Link>
                                {favorites.includes(sr.slug) ? (
                                  <span
                                    onClick={() =>
                                      this.deleteFromFavorites(sr.slug)
                                    }
                                    className="search-delete d-flex justify-content-center align-items-center mx-2 tr-3s pointer"
                                  >
                                    <i className="mdi mdi-trash-can-outline"></i>
                                  </span>
                                ) : null}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="input-icon">
                        {this.state.searchLoading ? (
                          <img
                            alt="_loading"
                            style={{ borderRadius: "50%" }}
                            src={images.Spinner}
                          />
                        ) : (
                          <i
                            aria-hidden="true"
                            className="mdi mdi-24px mdi-magnify text-white"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="list-btn mb-3 pointer"
                  onClick={this.showFavorites}
                >
                  <span className="list-btn-number bg-main font-10 text-white font-weight-bold d-block d-flex justify-content-center align-items-center">
                    {this.state.favoritesCount}
                  </span>
                  <div className="p-2 radius-10 bg-gray-2" id="favsBox">
                    <img
                      src={images.List}
                      width="35"
                      height="40"
                      alt=""
                      id="favsBoxImage"
                    />
                  </div>
                </div>

                <div className="row no-gutters mb-3 shadow p-3 px-lg-4 rounded">
                  {!this.state.isLoading ? (
                    <>
                      <div className="col-12 mb-3">
                        <div className="d-flex align-items-center">
                          <h2 className="font-weight-bold">
                            {this.state.word?.word}
                            {this.state.word?.voices && (
                              <i
                                className="mdi mdi-24px mdi-volume-high text-main pointer"
                                style={{ marginLeft: "7px" }}
                                onClick={this.start}
                              ></i>
                            )}
                          </h2>
                          <i
                            onClick={() => this.toggleOfFavorites()}
                            className={`mdi pointer mdi-36px mdi-star ml-auto ${
                              this.state.isInFavorites
                                ? "text-main"
                                : "empty-star"
                            }`}
                          ></i>
                        </div>
                        <div className="d-block abc">
                          <h3 className="font-weight-bold text-main">
                            {word?.main.length
                              ? word?.type === "es"
                                ? word?.main[0].data[0].azerbaijani?.word
                                : word?.main[0].data[0].spanish?.word
                              : null}
                            {word?.main.length && word?.type === "es"
                              ? word?.main[0].data[0].azerbaijani?.voices && (
                                  <i
                                    className="mdi mdi-24px mdi-volume-high text-main pointer"
                                    style={{ marginLeft: "7px" }}
                                    onClick={() =>
                                      this.startSub(
                                        word?.main[0].data[0].azerbaijani.voices
                                          .es
                                      )
                                    }
                                  ></i>
                                )
                              : null}
                            {word?.main.length && word?.type !== "es"
                              ? word?.main[0].data[0].spanish?.voices && (
                                  <i
                                    className="mdi mdi-24px mdi-volume-high text-main pointer"
                                    style={{ marginLeft: "7px" }}
                                    onClick={() =>
                                      this.startSub(
                                        word?.main[0].data[0].spanish.voices.es
                                      )
                                    }
                                  ></i>
                                )
                              : null}
                          </h3>
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <div
                          className="list-group user-list listmenu-filter rounded-pill px-1 py-1 bg-gray-2"
                          id="list-tab"
                          role="tablist"
                        >
                          <a
                            className={`list-group-item list-group-item-action rounded-pill bg-gray-2 border-0 p-0 d-inline float-left font-14 ${
                              this.state.tabKey === "dictionary" ? "active" : ""
                            }`}
                            id="list-home456-list"
                            data-toggle="list"
                            href="#list-home456"
                            onClick={() => this.handleTabChange("dictionary")}
                            role="tab"
                            aria-controls="home456"
                          >
                            <span className="py-1 d-block">
                              <i className="mdi mdi-book-open-page-variant vertical-align-inherit mr-1"></i>
                              {t("dictionary")}
                            </span>
                          </a>

                          {word?.is_conjugation ? (
                            <a
                              className={`list-group-item list-group-item-action rounded-pill bg-gray-2 border-0 p-0 d-inline float-left font-14 ${
                                this.state.tabKey === "conjugation"
                                  ? "active"
                                  : ""
                              }`}
                              id="list-home12-list"
                              data-toggle="list"
                              href="#list-home12"
                              onClick={() =>
                                this.handleTabChange("conjugation")
                              }
                              role="tab"
                              aria-controls="home12"
                            >
                              <span className="py-1 d-block">
                                <i className="far fa-list-alt mr-1"></i>
                                {t("conjugation")}
                              </span>
                            </a>
                          ) : null}

                          <a
                            className={`list-group-item list-group-item-action rounded-pill bg-gray-2 border-0 p-0 d-inline float-left font-14 ${
                              this.state.tabKey === "synonyms" ? "active" : ""
                            }`}
                            id="list-messages24-list"
                            data-toggle="list"
                            href="#list-messages24"
                            onClick={() => this.handleTabChange("synonyms")}
                            role="tab"
                            aria-controls="messages24"
                          >
                            <span className="py-1 d-block">
                              <i className="mdi mdi-alpha-s-box-outline vertical-align-inherit mr-1"></i>
                              {t("synonyms")}
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="tab-content" id="nav-tabContent2">
                          <div
                            className={`tab-pane fade ${
                              this.state.tabKey === "dictionary"
                                ? "show active"
                                : ""
                            }`}
                            id="list-home456"
                            role="tabpanel"
                            aria-labelledby="list-home456-list"
                          >
                            <ol className="pl-3 font-weight-bold">
                              {word?.main.length ? (
                                <span className="d-block text-secondary mb-2">
                                  {t("principalTranslation")}
                                </span>
                              ) : null}

                              {word?.main.map((m, index) => {
                                const single = m.data.length < 2;
                                return (
                                  <li key={index} className="mb-3">
                                    <div className="font-weight-normal">
                                      <span className="text-success d-block">
                                        {m.category && m.category[`name_${l}`]}
                                        {m.type &&
                                          ", " + m.type[`name_${l}`]}{" "}
                                      </span>
                                      {m.data.map((d, dindex) => {
                                        return (
                                          <span key={dindex}>
                                            <span className="text-main font-weight-bold d-block">
                                              {word?.type === "es"
                                                ? single
                                                  ? d.azerbaijani?.word || ""
                                                  : `- ${
                                                      d.azerbaijani?.word || ""
                                                    }`
                                                : single
                                                ? d.spanish?.word || ""
                                                : `- ${d.spanish?.word || ""}`}
                                              <span
                                                style={{ color: "#c4c4c4" }}
                                              >
                                                {word.type === "az" &&
                                                d.meaning_es
                                                  ? ` (${d.meaning_es})`
                                                  : null}
                                                {word.type === "es" &&
                                                d.meaning_az
                                                  ? ` (${d.meaning_az})`
                                                  : null}
                                              </span>
                                            </span>

                                            {d.sentences.map((s, sinddex) => {
                                              return (
                                                <span key={sinddex}>
                                                  <span className="d-block">
                                                    {s.sentence_az}
                                                  </span>

                                                  <span className="text-muted d-block">
                                                    {s.sentence_es}
                                                  </span>
                                                </span>
                                              );
                                            })}
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </li>
                                );
                              })}

                              {word?.additional.length ? (
                                <div className="text-muted mb-3">
                                  {t("additionalTranslation")}
                                </div>
                              ) : null}

                              {word?.additional.map((m, index) => {
                                const single = m.data.length < 2;
                                return (
                                  <li key={index} className="mb-3">
                                    <div className="font-weight-normal">
                                      <span className="text-success d-block">
                                        {m.category && m.category[`name_${l}`]}
                                        {m.type &&
                                          ", " + m.type[`name_${l}`]}{" "}
                                      </span>
                                      {m.data.map((d, dindex) => {
                                        return (
                                          <span key={dindex}>
                                            <span className="text-main font-weight-bold d-block">
                                              {word?.type === "es"
                                                ? single
                                                  ? d.azerbaijani?.word || ""
                                                  : `-${
                                                      d.azerbaijani?.word || ""
                                                    }`
                                                : single
                                                ? d.spanish?.word || ""
                                                : `-${d.spanish?.word || ""}`}
                                              <span
                                                style={{ color: "#c4c4c4" }}
                                              >
                                                {word.type === "az" &&
                                                d.meaning_es
                                                  ? ` (${d.meaning_es})`
                                                  : null}
                                                {word.type === "es" &&
                                                d.meaning_az
                                                  ? ` (${d.meaning_az})`
                                                  : null}
                                              </span>
                                            </span>

                                            {d.sentences.map((s, sinddex) => {
                                              return (
                                                <span key={sinddex}>
                                                  <span className="d-block">
                                                    {s.sentence_az}
                                                  </span>

                                                  <span className="text-muted d-block">
                                                    {s.sentence_es}
                                                  </span>
                                                </span>
                                              );
                                            })}
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </li>
                                );
                              })}
                            </ol>

                            {this.state.word?.phrases.length ? (
                              <ol className="pl-3 font-weight-bold">
                                <div className="text-muted mb-3">
                                  {t("phrases")}
                                </div>

                                {this.state.word &&
                                  this.state.word?.phrases.map(
                                    (ph, phindex) => {
                                      return (
                                        <li key={phindex} className="mb-3">
                                          <div className="font-weight-normal">
                                            <span className="text-success d-block">
                                              {ph.title_es}
                                            </span>

                                            <span className="text-muted d-block">
                                              {ph.title_az}
                                            </span>

                                            <span className="d-block">
                                              {ph.sentence?.sentence_es}
                                            </span>

                                            <span className="text-muted d-block">
                                              {ph.sentence?.sentence_az}
                                            </span>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                              </ol>
                            ) : null}
                          </div>

                          <div
                            className={`tab-pane fade ${
                              this.state.tabKey === "conjugation"
                                ? "show active"
                                : ""
                            }`}
                            id="list-home12"
                            role="tabpanel"
                            aria-labelledby="list-home12-list"
                          >
                            {this.state.conjugationFetched ? (
                              conjugation ? (
                                <>
                                  <div className="mb-3">
                                    {/* <span className="d-block font-weight-bold font-18">
                                    Participles
                                  </span> */}

                                    <div className="d-block">
                                      <span className="text-muted">
                                        {t("Present")}:
                                      </span>
                                      <span className="font-weight-bold">
                                        {" "}
                                        {conjugation?.present}
                                      </span>
                                    </div>

                                    <div className="d-block">
                                      <span className="text-muted">
                                        {t("Past")}:
                                      </span>
                                      <span className="font-weight-bold">
                                        {" "}
                                        {conjugation?.past}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    {parts
                                      .filter((pa) => !conjugation[pa].hide)
                                      .map((p, pindex) => {
                                        return (
                                          <div
                                            key={pindex}
                                            className="card border-0"
                                          >
                                            <div id="headingOne">
                                              <h2 className="mb-0">
                                                <button
                                                  className="btn btn-link btn-block text-left focus-none font-weight-bold text-main"
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target={`#collapse${pindex}`}
                                                  aria-expanded={
                                                    pindex === 0
                                                      ? "true"
                                                      : "false"
                                                  }
                                                  aria-controls={`collapse${pindex}`}
                                                >
                                                  {t(p)}
                                                  <i className="fas fa-chevron-up active-up tr-3s ml-3"></i>
                                                </button>
                                              </h2>
                                            </div>

                                            <div
                                              id={`collapse${pindex}`}
                                              className={`collapse ${
                                                pindex === 0 ? "show" : "hide"
                                              }`}
                                              aria-labelledby="headingOne"
                                              data-parent="#accordionExample"
                                            >
                                              <div className="card-body overflow-x-auto">
                                                <table className="table table-borderless shadow rounded">
                                                  <thead className="bg-gray-2">
                                                    <tr>
                                                      <th scope="col">#</th>
                                                      {conjugation[
                                                        p
                                                      ].headers.map(
                                                        (h, hindex) => {
                                                          return (
                                                            <th
                                                              key={hindex}
                                                              scope="col"
                                                            >
                                                              {t(h)}
                                                            </th>
                                                          );
                                                        }
                                                      )}
                                                    </tr>
                                                  </thead>

                                                  <tbody className="font-14">
                                                    {Object.keys(
                                                      conjugation[p].data
                                                    ).map((k, kindex) => {
                                                      return (
                                                        <tr key={kindex}>
                                                          <th
                                                            scope="row"
                                                            className="text-muted"
                                                          >
                                                            {k}
                                                          </th>
                                                          {conjugation[p].data[
                                                            k
                                                          ].map((d, dindex) => {
                                                            return (
                                                              <td key={dindex}>
                                                                {d}
                                                              </td>
                                                            );
                                                          })}
                                                        </tr>
                                                      );
                                                    })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </>
                              ) : null
                            ) : (
                              <div className="w-100">
                                <Skeleton
                                  count={8}
                                  height={15}
                                  width={400}
                                  style={{ maxWidth: "100%" }}
                                />
                                <Skeleton count={18} height={15} />
                              </div>
                            )}
                          </div>
                          <div
                            className={`tab-pane fade ${
                              this.state.tabKey === "synonyms"
                                ? "show active"
                                : ""
                            }`}
                            id="list-messages24"
                            role="tabpanel"
                            aria-labelledby="list-messages24-list"
                          >
                            {this.state.synonymsFetched ? (
                              <div className="overflow-x-auto">
                                {synonyms.map((sy, index) => {
                                  return (
                                    <table
                                      key={index}
                                      className="table table-borderless"
                                    >
                                      <tbody className="font-14 font-weight-bold">
                                        <tr>
                                          <td className="font-17 text-main">
                                            {sy[`title_${l}`]}
                                          </td>
                                        </tr>
                                        <tr>
                                          {sy.words.map((w, windex) => {
                                            return (
                                              <td
                                                key={windex}
                                                className="py-2 text-main"
                                              >
                                                {w.word.word}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="w-100">
                                <Skeleton
                                  count={8}
                                  height={15}
                                  width={400}
                                  style={{ maxWidth: "100%" }}
                                />
                                <Skeleton count={18} height={15} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-100">
                        <Skeleton
                          count={8}
                          height={15}
                          width={400}
                          style={{ maxWidth: "100%" }}
                        />
                        <Skeleton count={18} height={15} />
                      </div>
                    </>
                  )}
                </div>

                <div className="row ng-gutters mx-n1 mx-lg-n2 justify-content-center col-max-400">
                  <div className="col-6 col-lg-6 col-sm-6 col-lg-4 px-2 px-lg-n2 mb-3 mb-md-0">
                    <WordWidget
                      icon={images.PopularIcon}
                      word={this.props.info.data.popular_word?.word}
                      slug={this.props.info.data.popular_word?.slug}
                      voices={this.props.info.data.popular_word?.voices}
                      text={t("mostSearchedWord")}
                    />
                  </div>
                  <div className="col-6 col-lg-6 col-sm-6 col-lg-4 px-2 px-lg-n2 mb-3 mb-md-0">
                    <WordWidget
                      icon={images.CalendarIcon}
                      word={this.props.info.data.word_of_day?.word}
                      slug={this.props.info.data.word_of_day?.slug}
                      voices={this.props.info.data.word_of_day?.voices}
                      text={t("wordOfTheDay")}
                    />
                  </div>
                </div>
              </div>

              <div className="d-none d-lg-block col-12 col-lg-4">
                <Link to="/contact-us">
                  <div className="bg-gray w-100 rounded sticky-banner">
                    <img src={images.MainBanner} alt="Ad" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ info }) => {
  return {
    info,
  };
};

export default connect(mapStateToProps)(withTranslation()(Translation));
