import React, { useState, useEffect, useRef } from "react";
import { ArticleBlock } from "../Elements/Blocks";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import adicto from "../../const/api";
import Pagination from "../Elements/Pagination";
import { LoadingSkeleton } from "../Elements/LoadingSkeleton";
import images from "../../Images/images";
import { Link } from "react-router-dom";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    last_page: 15,
  });
  const [keyword, setKeyword] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const prevKeywordRef = useRef();

  useEffect(() => {
    const getArticles = async () => {
      setLoading(true);
      await adicto
        .get(`blog?page=${pagination.page}&per_page=8&key_word=${keyword}`)
        .then((res) => {
          setArticles(res.data.data);
          setPagination({
            page: res.data.current_page,
            last_page: res.data.last_page,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (
      prevKeywordRef.current === undefined ||
      prevKeywordRef.current === keyword
    ) {
      getArticles();
    }
    prevKeywordRef.current = keyword;
  }, [t, pagination.page, keyword, searchClicked]);

  const changePage = (page) => {
    let pagin = { ...pagination };
    pagin.page = page;
    setPagination(pagin);
  };

  return (
    <div className="services-section-one">
      <div className="container-lg">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <h3 className="mb-2 font-25 font-weight-bold">
                  {t("searchArticle")}
                </h3>
                <div className="input-active shadow-big rounded-pill input-group mb-3 mb-lg-4 mt-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border-0"
                    placeholder={t("searchWithDots")}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    required
                  />
                  <div className="input-icon">
                    <i
                      onClick={() => setSearchClicked(!searchClicked)}
                      aria-hidden="true"
                      className="mdi pointer mdi-24px mdi-magnify text-white"
                    ></i>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="row my-gutters">
                  {loading ? (
                    <LoadingSkeleton />
                  ) : (
                    articles.map((a, index) => {
                      return <ArticleBlock key={index} article={a} />;
                    })
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <Skeleton count={2} height={20} />
            ) : (
              <Pagination
                page={pagination.page}
                last_page={pagination.last_page}
                changePage={changePage}
              />
            )}
          </div>
          <div className="d-none d-lg-block col-12 col-lg-4">
            <Link to="/contact-us">
              <div className="bg-gray w-100 rounded sticky-banner">
                <img src={images.MainBanner} alt="Ad" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Articles;
