import React from "react";

export const ContactBox = (props) => {
  return (
    <span className="pointer contact-button tr-3s">
      <div className="bg-white shadow d-flex align-items-center justify-content-left p-3 rounded">
        <div className="bg-icon d-inline-block mr-2 flex-none">
          <i
            aria-hidden="true"
            className={`mdi mdi-24px ${props.class} rounded-circle`}
          ></i>
        </div>

        <span className="font-weight-bold text-dark font-17">
          {props.text}
        </span>
      </div>
    </span>
  );
};