import React from "react";
import { VocabularyBlock } from "../Elements/Blocks";
import adicto from "./../../const/api";
import { connect } from "react-redux";
import Pagination from "../Elements/Pagination";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { LoadingSkeleton } from "../Elements/LoadingSkeleton";
import images from "../../Images/images";
import { Link } from "react-router-dom";

class Vocabulary extends React.Component {
  state = {
    vocabularies: {},
    page: 1,
    per_page: 8,
    last_page: 1,
    keyword: "",
    level_id: 1,
    level_name: "A1",
    loading: true,
  };

  fetchVocabularies = async () => {
    const {
      per_page,
      keyword,
      level_id,
      level_name,
      vocabularies,
    } = this.state;
    this.setState({ loading: true });
    await adicto
      .get(
        `vocabularies?page=${vocabularies[level_name].page}&per_page=${per_page}&key_word=${keyword}&level_id=${level_id}`
      )
      .then((res) => {
        let vocabularies = { ...this.state.vocabularies };
        vocabularies[level_name].data = res.data.data;
        vocabularies[level_name].page = res.data.current_page;
        vocabularies[level_name].last_page = res.data.last_page;
        this.setState({
          vocabularies: vocabularies,
          page: res.data.current_page,
          last_page: res.data.last_page,
          loading: false,
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.info.level.length !== prevProps.info.level.length) {
      this.setLevels();
    }
    if (this.props.t !== prevProps.t) {
      this.fetchVocabularies();
    }
  }

  setLevels() {
    if (this.props.info.level.length > 0) {
      let vocabularies = {};
      this.props.info.level.forEach((l) => {
        vocabularies[l.name] = { page: 1, loading: true, data: [] };
      });
      this.setState(
        { vocabularies, level_id: this.props.info.level[0].id },
        () => {
          this.fetchVocabularies();
        }
      );
    }
  }

  changePage = (page) => {
    let vocabularies = { ...this.state.vocabularies };
    vocabularies[this.state.level_name].page = page;
    this.setState(
      {
        vocabularies,
        page,
      },
      () => {
        this.fetchVocabularies();
      }
    );
  };

  componentDidMount = () => {
    this.setLevels();
  };

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  setLevel = (level_id, level_name) => {
    this.setState({ level_id, level_name }, () => {
      if (!this.state.vocabularies[this.state.level_name].data.length) {
        this.fetchVocabularies();
      } else {
        this.setState({
          page: this.state.vocabularies[this.state.level_name].page,
          last_page: this.state.vocabularies[this.state.level_name].last_page,
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="services-section-one">
          <div className="container-lg">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="row no-gutters mx-n1 mx-md-n2 mb-3">
                  <div className="col-12 col-md-6 mb-3">
                    <h3 className="mb-2 font-25 font-weight-bold">
                      {t("searchTheme")}
                    </h3>
                    <div className="input-active shadow-big rounded-pill input-group mb-3 mb-lg-4 mt-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border-0"
                        placeholder={t("searchWithDots")}
                        aria-label={t("searchWithDots")}
                        value={this.state.keyword}
                        onChange={(e) =>
                          this.handleKeywordChange(e.target.value)
                        }
                        required
                      />
                      <div className="input-icon">
                        <i
                          onClick={this.fetchVocabularies}
                          aria-hidden="true"
                          className="mdi mdi-24px pointer mdi-magnify text-white"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    {this.props.info.level.length ? (
                      <div
                        className="list-group user-list listmenu-filter rounded-pill px-1 py-1 bg-gray-2"
                        id="list-tab"
                        role="tablist"
                      >
                        {this.props.info.level.map((l, index) => {
                          return (
                            <a
                              className={`list-group-item list-group-item-action rounded-pill bg-gray-2 border-0 p-0 d-inline float-left font-14 ${
                                index === 0 ? "active" : ""
                              }`}
                              key={index}
                              onClick={() => this.setLevel(l.id, l.name)}
                              id={`list-home${l.id}-list`}
                              data-toggle="list"
                              href={`#list-home${l.id}`}
                              role="tab"
                              aria-controls={`home${l.id}`}
                            >
                              <span className="py-1 d-block">{l.name}</span>
                            </a>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent2">
                      {this.state.loading ? (
                        <div className="row no-gutters">
                          <LoadingSkeleton />
                        </div>
                      ) : (
                        this.props.info.level.map((l, index) => {
                          return (
                            <div
                              className={`tab-pane fade show ${
                                l.id === this.state.level_id ? "active" : ""
                              }`}
                              key={index}
                              id={`list-home${l.id}`}
                              role="tabpanel"
                              aria-labelledby={`list-home${l.id}-list`}
                            >
                              <div className="row no-gutters">
                                {this.state.vocabularies[l.name]?.data.map(
                                  (v, index2) => {
                                    return (
                                      <VocabularyBlock key={index2} voc={v} />
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
                {this.state.loading ? (
                  <Skeleton count={2} height={20} />
                ) : this.state.vocabularies[this.state.level_name].last_page >
                  1 ? (
                  <Pagination
                    page={this.state.page}
                    last_page={this.state.last_page}
                    changePage={this.changePage}
                  />
                ) : null}
              </div>
              <div className="d-none d-lg-block col-12 col-lg-4">
                <Link to="/contact-us">
                  <div className="bg-gray w-100 rounded sticky-banner">
                    <img src={images.MainBanner} alt="Ad" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ info }) => {
  return {
    info,
  };
};

export default connect(mapStateToProps)(withTranslation()(Vocabulary));
