import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  es: {
    translation: {
      home: "Inicio",
      ourServices: "Nuestros servicios",
      aboutUs: "Sobre nosotros",
      contactUs: "Contacto",
      homeHeadingText: "Primera plataforma hispana de Azerbaiyán",
      translation: "Traducción",
      conjugation: "Conjugación",
      vocabulary: "Vocabulario",
      articles: "Artículos",
      texts: "Textos",
      mostSearchedWord: "Palabra más buscada",
      wordOfTheDay: "Palabra del día",
      allRightsReserved: "Todos los derechos reservados",
      author: "Ispanadicto",
      writeUs: "Escríbanos",
      searchByConjugation: "Buscar conjugación...",
      previous: "Atrás",
      next: "Adelante",
      wordbook: "Vocabulario",

      principalTranslation: "Traducción principal",
      additionalTranslation: "Traducción adicional",
      phrases: "Expresiones",
      search: "Buscar",
      searchWithDots: "Buscar...",
      dictionary: "Diccionario",
      synonyms: "Sinónimos",
      antonyms: "Antónimos",

      participles: "Participios",
      past: "Pasado",
      Past: "Pasado",
      preterite: "Pretérito",
      Preterite: "Pretérito",
      imperfect: "Imperfecto",
      Imperfect: "Imperfecto",
      conditional: "Condicional",
      Conditional: "Condicional",
      indicative: "Indicativo",
      subjuntive: "Subjuntivo",
      present: "Presente",
      Present: "Presente",
      imperfect2: "Imperfecto (2)",
      "Imperfect 2": "Imperfecto 2",
      future: "Futuro",
      Future: "Futuro",
      affirmative: "Afirmativo",
      Affirmative: "Afirmativo",
      negative: "Negativo",
      Negative: "Negativo",
      continuous: "Continuo",

      detailed: "más",
      contact: "Contáctenos",
      pageCreators: "Creadores del proyecto",
      name: "Nombre",
      mobileNumber: "Numéro de teléfono",
      note: "Su mensaje",
      send: "Enviar",
      searchArticle: "Buscar artículos",
      searchTheme: "Buscar un tema",
      word: "palabra",
      words: "palabras",
      readMore: "leer más",
      selectedWords: "Palabras destacadas",
      nouns: "Nombres",
      adjectives: "Adjetivos",
      verbs: "Verbos",
      ifadələr: "Expresiones",

      messageSucces: "Introduzca su nombre completo!",
      nameRequired: "Introduzca su nombre completo.",
      phoneRequired: "Introduzca correctamente su número de contacto.",
      messageRequired: "Introduzca su mensaje completo.",

      otherTranslations: "Otras traducciones",

      imperative: "Imperative",
      subjunctive: "Subjunctivo",
      perfect_subjunctive: "Perfecto de Subjunctivo",
      perfect: "Perfecto",
    },
  },
  az: {
    translation: {
      home: "Ana səhifə",
      ourServices: "Xidmətlərimiz",
      aboutUs: "Bizi tanı",
      contactUs: "Əlaqə",
      homeHeadingText: "Azərbaycanın ilk onlayn ispan dili platforması",
      translation: "Tərcümə",
      conjugation: "Təsriflənmə",
      vocabulary: "Lüğət",
      articles: "Məqalələr",
      texts: "Mətnlər",
      mostSearchedWord: "Ən çox axtarılan söz",
      wordOfTheDay: "Günün sözü",
      allRightsReserved: "Bütün hüquqlar qorunur",
      author: "Ispanadicto",
      writeUs: "Bizə yazın",
      searchByConjugation: "Təsriflənmə üzrə axtarın...",
      previous: "Geri",
      next: "İrəli",
      wordbook: "Sözlük",

      principalTranslation: "Əsas tərcümələr",
      additionalTranslation: "Əlavə tərcümələr",
      phrases: "İfadələr",
      search: "Axtarın",
      searchWithDots: "Axtarın...",
      dictionary: "Lüğət",
      synonyms: "Sinonimlər",
      antonyms: "Antonimlər",

      participles: "Təsriflənməyən formalar",
      present: "İndiki",
      Present: "İndiki",
      past: "Keçmiş",
      Past: "Keçmiş",
      preterite: "Keçmiş",
      Preterite: "Keçmiş",
      conditional: "Şərt",
      Conditional: "Şərt",
      future: "Gələcək",
      Future: "Gələcək",
      indicative: "Xəbər şəkli",
      subjuntive: "Subxuntiv",
      imperfect: "İmperfekt",
      Imperfect: "İmperfekt",
      imperfect2: "İmperfekt (2)",
      "Imperfect 2": "İmperfekt 2",
      affirmative: "Təsdiq",
      Affirmative: "Təsdiq",
      negative: "İnkar",
      Negative: "İnkar",
      continuous: "Davamedici",

      detailed: "ətraflı",
      contact: "Əlaqə saxla",
      pageCreators: "Layihə yaradıcıları",
      name: "Ad",
      mobileNumber: "Mobil nömrə",
      note: "Mesajınız",
      send: "Göndər",
      searchArticle: "Məqalə axtar",
      searchTheme: "Mövzu axtar",
      word: "söz",
      words: "söz",
      readMore: "daha çox",
      selectedWords: "Seçmə sözlər",
      nouns: "İsimlər",
      adjectives: "Sifətlər",
      verbs: "Fellər",
      ifadələr: "İfadələr",

      messageSucces: "Mesajınız uğurla göndərildi!",
      nameRequired: "Adınızı tam daxil edin.",
      phoneRequired: "Düzgün nömrə daxil edin.",
      messageRequired: "Mesajınızı tam daxil edin.",

      otherTranslations: "Digər tərcümələr",

      imperative: "Əmr",
      subjunctive: "Arzu şəkli",
      perfect_subjunctive: "Bitmiş zamanlar (arzu şəkli)",
      perfect: "Bitmiş zamanlar",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("locale") || "az",
    fallbackLng: "az",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
