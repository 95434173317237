import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ContactBox } from "./../Elements/ContactBox";
import adicto from "../../const/api";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import images from "../../Images/images";

const DetailService = (props) => {
  const [service, setService] = useState({});
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getService = async (id) => {
      await adicto
        .get(`services/${id}`)
        .then((res) => {
          setService(res.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getService(props.params.id);
  }, [props.params, t]);

  return (
    <>
      <div className="services-section-one">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row no-gutters mx-n1 mx-md-n2">
                <div className="col-12 mb-3 px-1 px-md-2">
                  <div className="card border-0 shadow">
                    {!loading ? (
                      <>
                        <div className="embed-responsive embed-responsive-21by9">
                          <img
                            src={service?.image}
                            alt=""
                            className="embed-responsive-item contain cover"
                          />
                        </div>
                        <div className="card-body">
                          <span className="card-title font-weight-bold text-main font-25 mb-2 d-block">
                            {service?.title}
                          </span>
                          <p className="card-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: service?.description,
                              }}
                            ></div>
                          </p>
                          <Link
                            to="/contact-us"
                            className="btn btn-primary my-2 bg-main"
                          >
                            {t("contact")}
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="p-20">
                        <Skeleton count={20} height={15} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12 mb-3 px-1 px-md-2">
                  <h2 className="font-weight-bold">{t("contactUs")}</h2>
                </div>

                {props.info.users.map((u) => {
                  return (
                    <>
                      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
                        <ContactBox
                          text={u.phone}
                          class={"mdi-phone-outline"}
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
                        <ContactBox
                          text={u.email}
                          class={"mdi-email-outline"}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="d-none d-lg-block col-12 col-lg-4">
              <Link to="/contact-us">
                <div className="bg-gray w-100 rounded sticky-banner">
                  <img src={images.MainBanner} alt="Ad" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ info }) => {
  return { info };
};

export default connect(mapStateToProps)(DetailService);
