import React from "react";
import images from "../../Images/images";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Footer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <footer
        className="py-3 pt-5"
        style={{ backgroundImage: `url(${images.FooterBg})` }}
      >
        <div className="container-lg">
          <div className="d-lg-flex align-items-center text-center">
            <span className="text-muted font-14">
              {t("author")} © 2020 {t("allRightsReserved")}
            </span>

            <ul
              role="toolbar"
              className="list-unstyled social-link d-flex ml-auto justify-content-center mb-0"
            >
              {this.props.info?.data?.facebook && (
                <li role="presentation" className="nav-item float-left">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.info?.data?.facebook || "#"}
                    className="nav-link"
                  >
                    <i
                      aria-hidden="true"
                      className="mdi mdi-24px text-main mdi-facebook"
                    ></i>
                  </a>
                </li>
              )}
              {this.props.info?.data?.instagram && (
                <li role="presentation" className="nav-item float-left">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.info?.data?.instagram || "#"}
                    className="nav-link"
                  >
                    <i
                      aria-hidden="true"
                      className="mdi mdi-24px text-main mdi-instagram"
                    ></i>
                  </a>
                </li>
              )}
              {this.props.info?.data?.linkedIn && (
                <li role="presentation" className="nav-item float-left">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.info?.data?.linkedIn || "#"}
                    className="nav-link"
                  >
                    <i
                      aria-hidden="true"
                      className="mdi mdi-24px text-main mdi-linkedin"
                    ></i>
                  </a>
                </li>
              )}
              {this.props.info?.data?.youtube && (
                <li role="presentation" className="nav-item float-left">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.info?.data?.youtube || "#"}
                    className="nav-link"
                  >
                    <i
                      aria-hidden="true"
                      className="mdi mdi-24px text-main mdi-youtube"
                    ></i>
                  </a>
                </li>
              )}
              {this.props.info?.data?.twitter && (
                <li role="presentation" className="nav-item float-left">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.props.info?.data?.twitter || "#"}
                    className="nav-link"
                  >
                    <i
                      aria-hidden="true"
                      className="mdi mdi-24px text-main mdi-twitter"
                    ></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = ({ info }) => {
  return {
    info,
  };
};

export default connect(mapStateToProps)(withTranslation()(Footer));
