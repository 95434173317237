import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ServiceBlock = (props) => {
  const { t } = useTranslation();
  return (
    <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
      <Link
        to={`/services/${props.service.id}`}
        className="font-weight-bold font-14 text-main"
      >
        <div className="item shadow card-bottom-one radius-10 overflow-hidden">
          <div className="card border-0 w-100">
            <div className="embed-responsive embed-responsive-16by9">
              <img
                src={props.service.image}
                alt=""
                className="embed-responsive-item"
              />
            </div>

            <div className="card-body">
              <span className="font-23 font-weight-bold card-title text-dark d-block mb-1">
                {props.service.title}
              </span>
              <span className="font-weight-bold font-14 text-main">
                {t("detailed")} <i className="mdi mdi-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const ArticleBlock = (props) => {
  const { t } = useTranslation();
  return (
    <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
      <Link
        to={`/articles/${props.article.id}`}
        className="d-block text-muted font-14"
      >
        <div className="item shadow rounded overflow-hidden">
          <div className="card border-0 w-100">
            <div className="embed-responsive embed-responsive-16by9">
              <img
                src={props.article.image.url}
                alt=""
                className="embed-responsive-item cover contain"
              />
            </div>

            <div className="card-body text-center">
              <button className="btn bg-main rounded-pill py-1 text-white mb-2 px-4">
                {props.article.title}
              </button>
              <span className="d-block text-muted font-14">
                {t("detailed")} <i className="mdi mdi-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const TextBlock = (props) => {
  const { t } = useTranslation();
  return (
    <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
      <Link to={`/texts/${props.text.id}`}>
        <div className="item shadow rounded overflow-hidden">
          <div className="card border-0 w-100">
            <div className="embed-responsive embed-responsive-16by9">
              <img
                src={props.text.image}
                alt=""
                className="embed-responsive-item cover contain"
              />
            </div>

            <div className="card-body">
              <button className="btn bg-main rounded-pill py-1 text-white mb-2 px-4">
                {props.text.title}
              </button>
              <p>
                <span style={{ color: "black" }}>{props.text.preview}</span>
                &nbsp; {t("readMore")}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const VocabularyBlock = (props) => {
  const { t } = useTranslation();
  return (
    <div className="col-12 col-md-6 mb-3 px-1 px-md-2">
      <Link to={`/vocabulary/${props.voc.id}`}>
        <div className="item shadow rounded overflow-hidden">
          <div className="card border-0 w-100">
            <div className="embed-responsive embed-responsive-16by9">
              <img
                src={props.voc.images}
                alt=""
                className="embed-responsive-item cover contain"
              />
            </div>
            <div className="card-body text-center">
              <button className="btn bg-main rounded-pill py-1 text-white mb-2 px-4">
                {props.voc.title}
              </button>
              <span className="d-block text-muted font-14">{props.voc.translations_count} {t("words")}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
