import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import images from "../../Images/images";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const Header = (props) => {
  const [lang, setLang] = useState("az");
  const changeLang = (lang) => {
    const { i18n } = props;
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
    setLang(lang);
  };

  useEffect(() => {
    setLang(
      localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
    );
  }, []);

  const style = {
    zIndex: 99999999999999,
    position: "fixed",
  };
  const { t } = props;
  return (
    <header
      style={style}
      className="main-header navbar navbar-expand-lg navbar-light bg-white p-0 w-100"
      id="nav"
    >
      <div className="container-lg">
        <Link to="/" className="navbar-brand logo position-relative">
          <img src={images.Logo} alt="" />
        </Link>

        <nav
          className="main-navber collapse navbar-collapse ml-lg-5"
          id="navbarSupportedContent"
        >
          <ul role="menubar" className="navbar-nav mr-lg-auto">
            <li
              role="presentation"
              className="nav-item position-relative tr-3s"
            >
              <NavLink
                to="/"
                exact
                className="nav-link rounded tr-3s"
                activeClassName="active"
              >
                {t("home")}
              </NavLink>
            </li>
            <li
              role="presentation"
              className="nav-item position-relative tr-3s"
            >
              <NavLink
                to="/services"
                className="nav-link rounded tr-3s"
                activeClassName="active"
              >
                {t("ourServices")}
              </NavLink>
            </li>
            <li
              role="presentation"
              className="nav-item position-relative tr-3s"
            >
              <NavLink
                to="/about"
                className="nav-link rounded tr-3s"
                activeClassName="active"
              >
                {t("aboutUs")}
              </NavLink>
            </li>
            <li
              role="presentation"
              className="nav-item position-relative tr-3s"
            >
              <NavLink
                to="/contact-us"
                className="nav-link rounded tr-3s"
                activeClassName="active"
              >
                {t("contactUs")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <ul className="navbar-nav language ml-auto flex-flow-nowrap">
          <li onClick={() => changeLang("az")}>
            <span
              className={`pointer language-link d-block text-center text-dark rounded ${
                lang === "az" ? "active" : null
              }`}
            >
              Az
            </span>
          </li>
          <li onClick={() => changeLang("es")}>
            <span
              className={`pointer language-link d-block text-center text-dark rounded ${
                lang === "es" ? "active" : null
              }`}
            >
              Es
            </span>
          </li>
        </ul>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </header>
  );
};
export default connect(null)(withTranslation()(Header));
