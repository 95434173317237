import axios from "axios";
import siteUrl from "./const";

let token = null;
if (localStorage.getItem("user")) {
  token = JSON.parse(localStorage.getItem("user"));
}

export const adicto = axios.create({
  baseURL: `${siteUrl}api`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token ? token.access_token : null}`,
  },
});

adicto.interceptors.request.use(
  (config) => {
    config.headers["accept-language"] = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "az";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default adicto;
