import React from "react";
import images from "../../Images/images";
import { Link } from "react-router-dom";
import { WordWidget } from "./../Elements/Widgets";
import { withTranslation } from "react-i18next";
import adicto from "./../../const/api";
import { connect } from "react-redux";
import history from "./../../const/history";

class Home extends React.Component {
  state = {
    isLoading: false,
    keyword: "",
    searchResults: [],
    open: false,
    favorites: [],
    arrow: null,
  };

  componentDidMount() {
    window.addEventListener("keydown", (event) => this.handleKeyDown(event));

    if (localStorage.getItem("favorites")) {
      this.setState({
        favorites: JSON.parse(localStorage.getItem("favorites")),
      });
    }

    window.addEventListener("click", (event) => this.toggleSearchOpen(event));
  }

  toggleSearchOpen = (e) => {
    if (e.target.id !== "searchResults" && e.target.id !== "searchBox") {
      this.setState({ open: false, arrow: null });
    }
  };

  handleKeyDown = (event) => {
    if (
      (event.keyCode >= 48 && event.keyCode <= 90) ||
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      document.getElementById("searchBox").focus();
    }
    if (event.target.id === "searchBox") {
      if (event.keyCode === 38) {
        if (this.state.searchResults.length > 0) {
          let arr = this.state.arrow;
          if (arr === null) {
            arr = 0;
          } else if (arr !== 0) {
            arr--;
          } else {
            arr = null;
          }
          this.setState({ arrow: arr });
        }
      } else if (event.keyCode === 40) {
        if (this.state.searchResults.length > 0) {
          let arr = this.state.arrow;
          if (arr === null) {
            arr = 0;
          } else if (arr < this.state.searchResults.length - 1) {
            arr++;
          } else {
            arr = null;
          }
          this.setState({ arrow: arr });
        }
      } else if (event.keyCode === 13) {
        if (this.state.searchResults.length > 0) {
          let url = `/${this.props.conjugation ? "conjugation" : "word"}/${
            this.state.searchResults[
              this.state.arrow !== null && this.state.arrow !== false
                ? this.state.arrow
                : 0
            ].slug
          }`;
          history.push(url);
        }
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("click", this.toggleSearchOpen);
  }

  addSpecialChar = (char) => {
    let keyword = this.state.keyword.split("");
    keyword.push(char);
    this.setState({ keyword: keyword.join("") }, () => {
      this.searchWords();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.t !== prevProps.t) {
      this.setState({ isLoading: true });
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 500);
    }
  }

  searchWords = async (e) => {
    const keyword = e;
    if (e.length > 1) {
      this.setState({ isLoading: true });
      await adicto
        .get("search", {
          params: {
            key_word: this.state.keyword,
            is_conjugation: this.props.conjugation ? "1" : "0",
          },
        })
        .then((res) => {
          if (keyword === this.state.keyword) {
            this.setState({
              searchResults: res.data,
              open: res.data.length > 0,
              arrow: false,
              isLoading: false,
            });
          }
        });
    } else if (e.length === 0) {
      this.setState({ searchResults: [], open: false });
    }
  };

  toggleSearchResult = (open) => {
    this.setState({ open: open });
  };

  handleOnFocus = () => {
    if (this.state.searchResults.length) {
      this.toggleSearchResult(true);
    } else if (!this.state.keyword.length) {
      const list = JSON.parse(localStorage.getItem("last_opened_words"));
      this.setState({
        searchResults: list || [],
        open: list && list.length > 0,
      });
    }
  };

  handleKeywordChange = async (e) => {
    this.setState({ keyword: e }, () => {
      this.searchWords(e);
    });
  };

  deleteFromFavorites = (slug) => {
    let favorites = [...this.state.favorites.filter((f) => f.slug !== slug)];
    this.setState({ favorites }, () => {
      localStorage.setItem("favorites", JSON.stringify(favorites));
    });
  };

  render() {
    const { t } = this.props;
    const favorites = this.state.favorites.map((f) => f.slug);
    return (
      <div
        className="section-one d-flex align-items-center"
        style={{ backgroundImage: `url(${images.SectionBg})` }}
      >
        <div className="container-lg">
          <div className="row justify-content-center my-4">
            <div className="col-12">
              <div className="text-center py-4 text-white main-title">
                <h1 className="font-lg-50 font-weight-bold">Ispanadicto</h1>

                <p className="mb-0">{t("homeHeadingText")}</p>
              </div>
            </div>

            <div className="col-12 col-md-6 d-flex justify-content-center">
              <div className="input-active input-group mb-3 mb-lg-4">
                <input
                  type="text"
                  className="form-control rounded-pill border-0"
                  placeholder={
                    this.props.conjugation
                      ? t("searchByConjugation")
                      : t("searchWithDots")
                  }
                  aria-label={
                    this.props.conjugation
                      ? t("searchByConjugation")
                      : t("searchWithDots")
                  }
                  value={this.state.keyword}
                  onFocus={() => this.handleOnFocus()}
                  onChange={(e) => this.handleKeywordChange(e.target.value)}
                  id="searchBox"
                  autoComplete="off"
                  required
                />
                <div
                  className={`position-absolute search-dropdown w-100 bg-white radius-10 p-3 shadow ${
                    this.state.open ? "d-block" : "d-none"
                  }`}
                  id="searchResults"
                >
                  <ul className="navbar-nav">
                    {this.state.searchResults.map((sr, index) => {
                      return (
                        <li
                          key={index}
                          // className="d-flex align-items-center bg-gray-2 rounded mb-1"
                          className={`d-flex align-items-center bg-gray-2 rounded mb-1 ${
                            this.state.arrow === index ? "active-result" : ""
                          }`}
                        >
                          <Link
                            to={`/${
                              this.props.conjugation ? "conjugation" : "word"
                            }/${sr.slug}`}
                            className="p-2 d-block flex-grow-1 text-dark"
                          >
                            {sr.word}
                          </Link>
                          {favorites.includes(sr.slug) ? (
                            <span
                              onClick={() => this.deleteFromFavorites(sr.slug)}
                              className="search-delete d-flex justify-content-center align-items-center mx-2 tr-3s pointer"
                            >
                              <i className="mdi mdi-trash-can-outline"></i>
                            </span>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="input-icon">
                  {this.state.isLoading ? (
                    <img
                      alt="_loading"
                      style={{ borderRadius: "50%" }}
                      src={images.Spinner}
                    />
                  ) : (
                    <i
                      aria-hidden="true"
                      className="mdi mdi-24px mdi-magnify text-white"
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row flex-lg-nowrap no-gutters mx-n1 mx-lg-n2 justify-content-center mb-3 mb-lg-4">
            <div className="col-6 col-sm-4 flex-lg-fill text-center mb-3 px-2 px-lg-3">
              <Link
                to="/"
                className="d-block bg-white p-3 shadow-lg rounded card-left-one"
              >
                <img
                  src={images.TranslationImg}
                  width="40"
                  height="40"
                  alt="Translation"
                />

                <span className="d-block font-18 font-weight-bold text-dark pt-2">
                  {t("translation")}
                </span>
              </Link>
            </div>
            <div className="col-6 col-sm-4 flex-lg-fill text-center mb-3 px-2 px-lg-3">
              <Link
                to="/conjugation"
                className="d-block bg-white p-3 shadow-lg rounded card-left-two"
              >
                <img
                  src={images.ConjugationImg}
                  width="40"
                  height="40"
                  alt="Translation"
                />

                <span className="d-block font-18 font-weight-bold text-dark pt-2">
                  {t("conjugation")}
                </span>
              </Link>
            </div>
            <div className="col-6 col-sm-4 flex-lg-fill text-center mb-3 px-2 px-lg-3">
              <Link
                to="vocabulary"
                className="d-block bg-white p-3 shadow-lg rounded card-left-three"
              >
                <img
                  src={images.VocabularyImg}
                  width="30"
                  height="38"
                  alt="Translation"
                />

                <span className="d-block font-18 font-weight-bold text-dark pt-2">
                  {t("wordbook")}
                </span>
              </Link>
            </div>
            <div className="col-6 col-sm-4 flex-lg-fill text-center mb-3 px-2 px-lg-3">
              <Link
                to="/articles"
                className="d-block bg-white p-3 shadow-lg rounded card-left-four"
              >
                <img
                  src={images.ArticlesImg}
                  width="32"
                  height="40"
                  alt="Translation"
                />
                <span className="d-block font-18 font-weight-bold text-dark pt-2">
                  {t("articles")}
                </span>
              </Link>
            </div>
            <div className="col-6 col-sm-4 flex-lg-fill text-center mb-3 px-2 px-lg-3">
              <Link
                to="/texts"
                className="d-block bg-white p-3 shadow-lg rounded card-left-four"
              >
                <img
                  src={images.TextsImg}
                  width="37"
                  height="38"
                  alt="Translation"
                />

                <span className="d-block font-18 font-weight-bold text-dark pt-2">
                  {t("texts")}
                </span>
              </Link>
            </div>
          </div>

          <div className="row ng-gutters mx-n1 mx-lg-n2 justify-content-center col-max-400">
            <div className="col-6 col-sm-6 col-lg-4 px-2 px-lg-n2 mb-3 mb-md-0">
              <WordWidget
                icon={images.PopularIcon}
                word={this.props.info.data.popular_word?.word}
                slug={this.props.info.data.popular_word?.slug}
                voices={this.props.info.data.popular_word?.voices}
                text={t("mostSearchedWord")}
              />
            </div>
            <div className="col-6 col-sm-6 col-lg-4 px-2 px-lg-n2 mb-3 mb-md-0">
              <WordWidget
                icon={images.CalendarIcon}
                word={this.props.info.data.word_of_day?.word}
                slug={this.props.info.data.word_of_day?.slug}
                voices={this.props.info.data.word_of_day?.voices}
                text={t("wordOfTheDay")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ info }) => {
  return {
    info,
  };
};

export default connect(mapStateToProps)(withTranslation()(Home));
